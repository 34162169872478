import React from "react"
import { graphql } from "gatsby"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useMediaQuery } from "@material-ui/core"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import MainToolbar from "../components/main-toolbar"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"
import backgroundImage from "../images/3d-face-surgery.jpg"
import LineTitle from "../components/LineTitle"
import Images from "../styles/Images"
import sale from "../images/150e.png"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Youtube from "../components/Video/Youtube"
import HelpOutline from "@material-ui/icons/HelpOutline"
// import QAAccordion from "../components/accordion"

const useStyles = makeStyles((theme) => ({
  bannerGrid: {
    flexDirection: "row",
    ["@media (max-width:600px)"]: {
      paddingBottom: "0px",
    },
  },
  bannerLeft: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  beforeAfter: {
    maxWidth: 290,
    // [theme.breakpoints.down("xl")]: {
    //   maxWidth: 330,
    // },
  },
  bodyHeader: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 600,
    color: "#202020",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  bodyText: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
    paddingBottom: 20,
  },
  bodyText2: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "#202020",
    maxWidth: 600,
    whiteSpace: "pre-line",
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  introHeader: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
  },
  book3d2: {
    display: "inline-block",
    color: "#fff!important",
    fontSize: "14px!important",
    backgroundColor: "red",
    borderRadius: "40px!important",
    padding: "1.0em 4em!important",
    justifyContent: "flex-start",
    alignself: "flex-start",
    textDecoration: "none!important",
    // paddingBottom: 20,
    "&:hover": {
      textDecoration: "none!important",
      opacity: "0.5!important",
      backgroundColor: "#929B91!important",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "24px!important",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "1800px",
    alignItems: "center",
    paddingTop: "100px",
    paddingBottom: "120px",
    backgroundColor: "#202020",
  },
  mainContainer: {
    padding: "0 !important",
    paddingBottom: "120px",
    backgroundColor: "#202020",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "60px",
    },
  },
  pageTitle: {
    paddingTop: "80px",
  },
  tooLongtitle: {
    color: "white",
    maxWidth: 400,
    ["@media (min-width:1440px)"]: {
      fontSize: "5rem",
    },
    ["@media (max-width:600px)"]: {
      fontSize: "2.4rem",
      maxWidth: 300,
    },
  },
  tooLongSubheader: {
    color: "white",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    maxWidth: "550px",
    ["@media (max-width:600px)"]: {
      fontSize: "1.3rem",
    },
  },
  lineContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1rem",
    borderLeft: "3px solid #565A56",
    width: "100%",
  },
  threeD: {
    height: 600,
    ["@media (max-width:600px)"]: {
      display: "none",
    },
  },
  selfies: {
    paddingTop: 80,
    paddingBottom: 40,
    height: 500,
    alignself: "center",
    ["@media (max-width:600px)"]: {},
  },
  videoText: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
  },
  reg: {
    paddingTop: 160,
    paddingBottom: 80,
    width: 200,
    ["@media (max-width:600px)"]: {
      paddingTop: 60,
    },
  },
  backgroundImg: {
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "right",
      height: 260,
    },
  },
  imageContainer: {
    paddingTop: 80,
  },
  videoContainer: {
    maxHeight: 400,
  },
  sectionContainer: {
    paddingTop: 80,
    paddingBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  sectionContainerExplanation: {
    paddingTop: 60,
    paddingBottom: 30,
    background: "white",
    // background: "#343640",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  faceList: {
    fontSize: "1.1rem",
    color: "#202020",
    maxWidth: 600,
    marginBlockStart: 0,
    marginBlockEnd: 0,
    textAlign: "left",
  },
  questions: {
    fontSize: "0.9rem",
    textAlign: "left",
    color: "#929B91",
    maxWidth: 350,
    whiteSpace: "pre-line",
    paddingBottom: 10,
  },
  helpOutline: {
    color: "#929B91",
    // display: "inline-block",
  },
  faq: {
    // display: "inline-block",
    color: "#929B91",
    fontSize: "1.1rem",
    fonWeight: "bold",
  },
  qaList: {
    paddingBottom: 10,
  },
}))

const Covid = ({ intl, data, location }) => {
  const classes = useStyles()
  const theme = useTheme()
  const t = (id) => intl.formatMessage({ id })
  const banner = data.bannerImage.childImageSharp.fluid
  const faceExplained = [
    t("deepPlane.faceExplained.1"),
    t("deepPlane.faceExplained.2"),
    t("deepPlane.faceExplained.3"),
    t("deepPlane.faceExplained.4"),
  ]
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Layout>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        title={intl.formatMessage({ id: "covid.SEOtitle" })}
        description={intl.formatMessage({ id: "covid.SEO" })}
        path={location.pathname}
      />
      <MainToolbar black />
      <Container maxWidth={"xl"} className={classes.mainContainer}>
        <BackgroundImage
          fluid={banner}
          className={classes.backgroundImg}
          backgroundColor={`#333`}
        >
          <Grid container spacing={0} className={classes.bannerGrid}>
            <Grid item xs={12} sm={8} className={classes.bannerLeft}>
              <Container>
                <LineTitle
                  header={t("covid.title")}
                  subheader={t("covid.subtitle")}
                  className={classes.pageTitle}
                  titleStyle={classes.tooLongtitle}
                  lightDecoLine
                  subHeaderStyle={classes.tooLongSubheader}
                />
              </Container>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </BackgroundImage>
        <Grid container spacing={12} className={classes.sectionContainer}>
          <Grid item xs={12} sm={6} align="center">
            {/* <Typography
              component="h2"
              variant="h4"
              className={classes.introHeader}
            >
              {t("deepPlane.introTitle")}
            </Typography>
            <p className={classes.bodyText}>{t("deepPlane.introText")}</p> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            align="center"
            className={classes.imageContainer}
          >
            {/* <Img
              fluid={data.front.childImageSharp.fluid}
              alt="Before and after cheek"
              className={classes.beforeAfter}
            ></Img>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            align="center"
            className={classes.imageContainer}
          >
            <Img
              fluid={data.side.childImageSharp.fluid}
              alt="Before and after facelift"
              className={classes.beforeAfter}
            ></Img> */}
          </Grid>
        </Grid>

        {/* <Grid container spacing={12} className={classes.sectionContainer}>
          <Grid
            item
            xs={12}
            sm={6}
            align="center"
            className={classes.videoContainer}
          >
            <Youtube
              videoUrl="https://www.youtube.com/watch?v=Pjsr7wclc9Q"
              imgFluid={data.explained.childImageSharp.fluid}
            ></Youtube>
          </Grid>
          <Grid item xs={12} sm={6} align="center">
            <Typography
              component="h2"
              variant="h4"
              className={classes.videoText}
            >
              {t("deepPlane.videoText")}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={12} className={classes.sectionContainer}>
          <Grid item xs={12} sm={6} align="center">
          </Grid>
          <Grid item xs={12} sm={6} align="center"></Grid>
        </Grid> */}

        {/* <Grid container spacing={12} className={classes.sectionContainer}>
          <Grid item xs={12} sm={6} align="center">
            <div className={classes.qaList}>
              <HelpOutline fontSize="large" className={classes.helpOutline} />
              <p className={classes.faq}>{t("deepPlane.qa")}</p>
            </div>
            <p className={classes.questions}>{t("deepPlane.questions")}</p>
          </Grid>
          <Grid item xs={12} sm={6} align="center"></Grid>
        </Grid> */}
      </Container>
    </Layout>
  )
}

export default injectIntl(Covid)

export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "hero-banner-covid.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    front: file(relativePath: { eq: "front-min.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    side: file(relativePath: { eq: "side-min.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    explained: file(relativePath: { eq: "deep-plane-facelift-explained.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
